import React, { useState } from "react";
import "./styles.scss";

export const Marker = React.memo(function Marker({ markerImage, markerType }) {
  const [show, setShow] = useState("hide");
  const marker = document.getElementById("arrow");

  return (
    <>
      <div
        id="marker"
        aria-describedby="tooltip"
        onMouseOver={() => setShow("show")}
        onMouseOut={() => setShow("hide")}
      >
        <img src={markerImage} alt="marker" className="d-block" width="60px" />
      </div>
      <div
        id="tooltip"
        role="tooltip"
        data-popper-placement="bottom"
        data-show={show}
      >
        {markerType}
      </div>
    </>
  );
});
