import React, { useState } from 'react';
import { debounce } from '@util'
import AsyncSelect from 'react-select/async';

const token = document.getElementsByName(
  "csrf-token"
)[0].content;

const defaultFetchHeader = {
  'X-CSRF-Token': token,
  'Content-Type': 'application/json'
  // 'Content-Type': 'application/x-www-form-urlencoded'
};

const fetchExhibitors = (keyword) =>
  fetch(`/admin/exhibitors.json?q[email_cont]=${keyword}`)
    .then(resp => resp.json())
    .then(results =>
      results.map(
        (result) => ({ label: result.email, value: result.id })
      )
    )

const addExhibitor = (boothId, exhibitorId) => 
  fetch(`/admin/booths/${boothId}/add_exhibitor`, {
    method: 'POST',
    headers: defaultFetchHeader,
    body: JSON.stringify({
      exhibitor_id: exhibitorId 
    })
  }).then(resp => (resp.ok ? resp.json() : Promise.reject(resp.status)))

const removeExhibitor = (boothId, exhibitorId) => 
  fetch(`/admin/booths/${boothId}/remove_exhibitor`, {
    method: 'POST',
    headers: defaultFetchHeader,
    body: JSON.stringify({
      exhibitor_id: exhibitorId
    })
  }).then(resp => (resp.ok ? exhibitorId : Promise.reject(resp.status)))


const debouncedFetchExhibitors = debounce((keyword, callback) => {
  fetchExhibitors(keyword).then(results => callback(results))
}, 500)

export default function BoothExhibitors(props) {
  const boothId = props.boothId;

  const [exhibitors, setExhibitors] = useState(props.exhibitors);
  const [selected, setSelected] = useState(null);
  const [formStatus, setFormStatus] = useState('pending');

  const isFormLoading = formStatus == 'loading';

  const onExhibitorSelect = (option) => { setSelected(option) }
  const onAddExhibitor = (e) => {
    e.preventDefault()
    const newExhibitor = { id: selected.value, email: selected.label };
    setFormStatus('loading')

    addExhibitor(boothId, newExhibitor.id).then(() => {
      setExhibitors([...exhibitors, newExhibitor])
      setSelected(null)
      setFormStatus('pending')
      window.triggerToast('Exhibitor added!')
    }).catch((error) => {
      setSelected(null)
      setFormStatus('pending')
      alert('Exhibitor has already been added.')
    })
  }

  const onRemoveExhibitor = (e, index) => {
    e.preventDefault()
    const exhibitor = exhibitors[index]

    const confirm = window.confirm(`Are you sure you want to remove ${exhibitor.email} from this booth?`)
    if (!confirm) return 

    removeExhibitor(boothId, exhibitor.id).then(() => {
      const updatedList = [...exhibitors]
      updatedList.splice(index, 1);
      setExhibitors(updatedList);
      window.triggerToast('Exhibitor removed.')
    }).catch((error) => {
      alert('Unable to remove exhibitor.');
    })
  }

  return (
    <>
      <div className='mb-5'>
        <div className="col-3 mb-2">
          <AsyncSelect
            cacheOptions
            value={selected}
            onChange={onExhibitorSelect}
            loadOptions={debouncedFetchExhibitors}
            defaultOptions
          />
        </div>
        <button
          disabled={!selected || isFormLoading }
          onClick={onAddExhibitor}
          className='btn btn-primary'
        >
          { isFormLoading && 'Loading' }
          { !isFormLoading && 'Add exhibitor'}
        </button>
      </div>
      <div className='mb-3'>
        <h4>Exhibitors ({exhibitors.length})</h4>
        {
          exhibitors.map((exhibitor, index) => (
            <div className='row mb-2' key={exhibitor.id}>
              <div className="col-3">
                { exhibitor.email }
              </div>
              <div className="col-3">
                <a
                  onClick={(e) => onRemoveExhibitor(e, index)}
                  style={{ cursor: 'pointer' }}
                >
                  Remove
                </a>
              </div>
            </div>
          ))
        }
      </div>
    </>
  )
}
