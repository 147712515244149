import React from 'react'
import { Modal, Carousel, Form, Button } from 'react-bootstrap'


export default function BoothModal({ buttonType, content, showModal, setShowModal, handleVideo }) {
 

  if (buttonType === "wallpaper" || buttonType === "banner") {
    return (
      <Modal id="gallery-modal" show={showModal} onHide={()=>setShowModal(false)} aria-labelledby="images-modal" centered size={ buttonType === "wallpaper" ?"xl":"sm"} > 
        <Modal.Body className="p-0">
          {content.length > 0 ?
            <Carousel>
              {content.map((poster,index)=>(
                <Carousel.Item key={`poster-${index}`} interval={4000}>
                  <img className="d-block" src={poster.url} alt={`poster-${index}`} width="100%" />
                </Carousel.Item>
              ))}
            </Carousel>
            :
            <h5>No poster available.</h5>
          }
        </Modal.Body>
      </Modal>
    )
  }
  if (buttonType === "vid" || buttonType === "pdf" ) {
    return (
      <Modal id="listing-modal" show={showModal} onHide={()=>setShowModal(false)} aria-labelledby="video-modal" centered > 
        {
          buttonType === "pdf" ?
          <>
            <Modal.Header closeButton>
              <h6>Select file(s) to preview</h6>
            </Modal.Header>
            <Modal.Body>
              {content.map(file=>(
                <div key={file.filename} className="file-container">
                  <p>{file.filename}</p>
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    <button>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#F83636" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="#F83636" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline stroke="#F83636" points="7 10 12 15 17 10"></polyline>
                        <line stroke="#F83636" x1="12" y1="15" x2="12" y2="3"></line>
                      </svg>
                    </button>
                  </a>
                </div>
              ))}
            </Modal.Body>
          </>
          :
          <>
            <Modal.Header closeButton>
              <h6>Select video(s) to play</h6>
            </Modal.Header>
            <Modal.Body>
              {content.map((video,index)=>(
                <div key={`video-${index}`} className="file-container" onClick={()=>handleVideo(video)}>
                  <p>{video.filename}</p>
                  <button>
                    <svg width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#F83636" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <circle cx="12" cy="12" r="10"></circle>
                      <polygon points="10 8 16 12 10 16 10 8"></polygon>
                    </svg>
                  </button>
                </div>
              ))}
            </Modal.Body>
          </>
        }
      </Modal>
    )
  }
  if (buttonType === "namecard") {
    return (
      <Modal id="namecard-modal" show={showModal} onHide={()=>setShowModal(false)} aria-labelledby="namecard-modal" centered > 
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>Drop <strong>{content}</strong> your name card!</p>
          <p>
            This will send {content} an email with your contact information
            indicating that you wish to connect.
          </p>
          <p>You can also include custom message:</p>
          <Form.Control placeholder="(Optional)" as="textarea" rows={3} />
          <Button className="mt-2">
            Drop your name card
          </Button>
        </Modal.Body>
      </Modal>
    )
  }
  if (buttonType === "info") {
    return (
      <Modal id="company-modal" show={showModal} onHide={()=>setShowModal(false)} aria-labelledby="company-modal" size="lg" centered > 
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            {content.companyLogo.url && <img className="d-block mx-auto" src={content.companyLogo.url} width="20%" alt="logo" /> }
            <figcaption className="text-center figure-caption">Company Logo</figcaption>
          </div>
          <h6>{content.title}</h6>
          <div className="content" dangerouslySetInnerHTML={{ __html: content.content }} />
          <div>Visit Our Website: <a href={content.companyWebsiteUrl} target="_blank" rel="noopener noreferrer">{content.companyWebsiteUrl}</a></div>
          <div>Drop us Message: <a href={`https://wa.me/${content.companyNumber}`} target="_blank" rel="noopener noreferrer">{content.companyNumber}</a></div>
          <div>Video Call: <a href={content.companyVideoChatLink} target="_blank" rel="noopener noreferrer">Join Here!</a></div>
          <div className="contact">
            <div >
              <h5 className="p-0 fw-bold">
                Contact Informations
                <svg width="25.344" height="25.359" viewBox="0 0 25.344 25.359">
                  <g transform="translate(-128.654 -469.282)">
                    <path d="M142.119,494.641h-1.584a1.442,1.442,0,0,0-.191-.04,12.406,12.406,0,0,1-5.483-1.742.429.429,0,0,0-.391-.041q-2.574.83-5.151,1.651c-.1.031-.2.057-.328.1.037-.119.058-.2.083-.273q.835-2.493,1.675-4.986a.46.46,0,0,0-.05-.452,12.231,12.231,0,0,1-1.856-4.726c-.075-.454-.127-.911-.189-1.367v-1.584c.014-.072.032-.144.043-.216.083-.562.135-1.131.252-1.685a12.671,12.671,0,1,1,14.562,15.174C143.049,494.526,142.583,494.578,142.119,494.641Zm-10.64-2.537c.115-.032.185-.05.253-.072.958-.3,1.918-.6,2.871-.918a.6.6,0,0,1,.586.077,10.9,10.9,0,0,0,8.459,1.619,11.088,11.088,0,1,0-13.41-11.059,10.978,10.978,0,0,0,2.191,6.817.473.473,0,0,1,.077.494C132.162,490.057,131.831,491.058,131.479,492.1Z" fill="#2af694"/>
                    <path d="M139.034,481.01a8.083,8.083,0,0,0,4.007,3.393c.234-.318.465-.628.693-.941a1.411,1.411,0,0,1,1.9-.545c.9.4,1.79.837,2.677,1.274a1.107,1.107,0,0,1,.652,1.054c0,1.833-.75,2.981-2.787,3.648a4.78,4.78,0,0,1-2.8-.112,13.731,13.731,0,0,1-7.673-5.554,9.107,9.107,0,0,1-1.528-2.836,4.456,4.456,0,0,1,1.121-4.608,2.355,2.355,0,0,1,1.647-.687c1.233-.06,1.593.176,2.071,1.32.295.707.574,1.421.893,2.117a1.544,1.544,0,0,1-.238,1.749C139.474,480.525,139.26,480.754,139.034,481.01Zm5.9,6.464a3.024,3.024,0,0,0,1.593-.455,1.6,1.6,0,0,0,.829-1.348.3.3,0,0,0-.123-.243c-.678-.338-1.366-.657-2.047-.989-.123-.06-.186-.035-.261.074-.178.257-.366.509-.56.755a1.557,1.557,0,0,1-2.071.543,9.692,9.692,0,0,1-4.711-4.133,1.234,1.234,0,0,1,.123-1.556c.215-.261.455-.5.668-.763a.308.308,0,0,0,.043-.258c-.306-.755-.623-1.506-.942-2.257-.026-.061-.083-.151-.131-.154a1.087,1.087,0,0,0-.947.233,2.873,2.873,0,0,0-.735,2.9,7.192,7.192,0,0,0,1.307,2.449,12.11,12.11,0,0,0,5.913,4.678A7.98,7.98,0,0,0,144.935,487.474Z" fill="#2af694"/>
                  </g>
                </svg>
              </h5>
            </div>
            {content.boothAgents.map((agent,index)=>(
              <div key={`agent-${index+1}`} className="mb-4" >
                <p className="my-0 fw-bold">{agent.name}</p>
                <p className="my-1">
                  <svg width="20" height="20" viewBox="0 0 24 24">
                    <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" fill="#FFAD2C"/>
                  </svg>
                  <span className="ms-1"><a href={`https://wa.me/${agent.phone}`} target="_blank" rel="noopener noreferrer">{agent.phone}</a></span>
                </p>
                <p className="my-1">
                  <svg width="20" height="20" viewBox="0 0 24 24">
                    <path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z" fill="#FFAD2C"/>
                  </svg>
                  <span className="ms-1"><a href={`mailto:${agent.email}`} target="_blank" rel="noopener noreferrer">{agent.email}</a></span>
                </p>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    )
  }
  return <></>
}

