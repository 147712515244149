import React from "react";
import "../styles";
const Table = ({ data }) => {
  return (
    <table className="tracking-table">
      <thead>
        <tr>
          <th className="tracking-table-header">User ID</th>
          <th>User Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((user, index) => {
          return (
            <tr key={`user-data-${index}`} className="tracking-table-row">
              <td>{user.userId || user.id}</td>
              <td>{user.name || user.full_name}</td>
              <td>{user.email}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
