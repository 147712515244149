// src/controllers/hello_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'country',
    'ic',
    'passport'
  ]
  connect() {
    this.hasIcTarget ? this.toggleIC() : null
    this.hasPassportTarget ? this.togglePassport() : null
  }

  toggleIC() {
    if (this.countryTarget.value == 'MY') {
      this.icTarget.disabled = false
      this.passportTarget.disabled = true
      this.passportTarget.value = ""
      
    } else {
      this.icTarget.disabled = true
      this.passportTarget.disabled = false
      this.icTarget.value = ""
    }
  }
  togglePassport() {
    if (this.countryTarget.value == 'MY') {
      this.passportTarget.disabled = true
    } else {
      this.passportTarget.disabled = false
    }
  }
  toggleCountry() {
    if (this.countryTarget.value == 'MY') {
      this.passportTarget.disabled = true
      this.icTarget.disabled = false
      this.passportTarget.value = ""
    } else {
      this.passportTarget.disabled = false
      this.icTarget.disabled = true
      this.icTarget.value = ""
    }
  }
}
