import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { firebaseDb } from '../../../../../config/firebaseConfig'
import Table from './component/table'
import './styles'

const token = document.getElementsByName('csrf-token')[0].content
const defaultFetchHeader = {
  'X-CSRF-Token': token,
  'Content-Type': 'application/x-www-form-urlencoded',
}

const fetchUsers = (callback) => {
  return () => {
    axios
      .request({
        url: '/users/all',
        method: 'GET',
        headers: defaultFetchHeader,
      })
      .then((resp) => resp.data)
      .then((json) => callback(json))
      .catch((err) => console.log(err))
  }
}

const countDbRef = `${
  process.env.NODE_ENV === 'production' ? 'production' : 'local'
}/userCount`

const LiveTracking = () => {
  const [users, setUsers] = useState([])
  const [userCountData, setUserCountData] = useState([])

  useEffect(fetchUsers(setUsers), [])

  useEffect(() => {
    const ref = firebaseDb.ref(countDbRef)
    ref.on(
      'value',
      (snapshot) => {
        const data = snapshot.val() || {}
        setUserCountData(
          Object.keys(data).map((key) => ({
            pageName: key,
            userIds: Object.keys(data[key]),
          }))
        )
      },
      (errorObject) => {
        console.log('The read failed: ' + errorObject.code)
      }
    )
  }, [countDbRef])

  //convert array to property
  // convert from [{pageName:'', userIds:['']}] to {pageName:[userIds], pageName:[userIds]}
  const _userCountData = {}
  if (userCountData.length > 0) {
    for (let i = 0; i < userCountData.length; i++) {
      _userCountData[userCountData[i].pageName] = parseInt(
        userCountData[i].userIds
      )
    }
  }

  const getAbsentees = () => {
    let attendeeIds = []
    Object.keys(_userCountData).forEach((pageName) => {
      attendeeIds = attendeeIds.concat(_userCountData[pageName])
    })
    return users
      .filter((u) => !attendeeIds.includes(u.id))
      .sort((a, b) => (a.full_name > b.full_name ? 1 : -1))
  }

  const getAttendees = (targetPage) => {
    if (userCountData.length > 0) {
      const hallAttendees =
        userCountData && userCountData?.find((x) => x.pageName === targetPage)
      if (hallAttendees) {
        const { userIds } = hallAttendees
        //convert list of userIds string to list of userIds integer
        const _userIds = userIds.map((userId) => Number(userId))
        return users
          .filter((u) => _userIds.includes(u.id))
          .sort((a, b) => (a.full_name > b.full_name ? 1 : -1))
      } else {
        return []
      }
    }
  }

  const absentees = getAbsentees()
  const totalOnlineCount = userCountData.reduce(
    (sum, data) => sum + (data?.userIds?.length || 0),
    0
  )

  return (
    <div>
      <div id="liveCount">
        <div className="item">
          <strong>Total: {totalOnlineCount}</strong>
        </div>
        {userCountData.map(({ pageName, userIds = [] }) => (
          <div className="item" key={pageName}>
            {pageName} : <span>{userIds.length}</span>
          </div>
        ))}
      </div>
      {userCountData.map(({ pageName, userIds = [] }) => {
        const pageData = getAttendees(pageName)
        return (
          <div className="eachPage" key={pageName}>
            <p className="title">
              <span>
                {pageName} ({userIds.length})
              </span>
            </p>
            <Table data={pageData} />
          </div>
        )
      })}

      {absentees && absentees.length > 0 && (
        <div className="eachPage">
          <p className="title">Offline ({absentees.length})</p>
          <Table data={absentees} />
        </div>
      )}
    </div>
  )
}

export default LiveTracking
