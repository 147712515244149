import React, { useState } from 'react'

export default function VideoUrlsInput({ urls: oriUrls }) {
  const [urls, setUrls] = useState([...oriUrls])

  const onAddUrl = (event) => {
    event.preventDefault()
    const urlInput = event.target.elements.url
    const url = urlInput.value
    if(!url) return

    setUrls([...urls, url])
    urlInput.value = ''
  }

  const removeUrl = (index) => {
    const confirmed = confirm(`Are you sure you want to remove ${urls[index]}?`)
    if(!confirmed) return

    const newUrls = [...urls]
    newUrls.splice(index, 1)
    setUrls(newUrls)
  }

  return (
    <>
      <label className='label'>Video Gallery</label>
      <form style={{ marginLeft: '25%', marginBottom: '10px' }} onSubmit={onAddUrl}>
        <input type='text' name='url' style={{ marginBottom: '10px' }}/>
        <button>Add url</button>
      </form>
      <div style={{ marginLeft: '25%', marginTop: '10px' }}>
        {
          urls.map((url, index) => (
            <div key={url + index} style={{ marginBottom: '0' }}>
              <span
                style={{ color: 'red', marginRight: '5px', cursor: 'pointer' }}
                onClick={() => removeUrl(index)}
              >
               x
              </span>
              <span
                style={{ fontSize: '14px' }}
              >
                <a href={url}>{ url }</a>
              </span>
              <input type='hidden' value={url} name='booth[video_urls][]' />
            </div>
          ))
        }
      </div>
    </>
  )
}
