import * as React from "react";
import { forwardRef, useEffect, useState } from "react";
import ClickToEdit from "react-click-to-edit";

const roleMapping = {
  delegate: "Delegate",
  exhibitor: "Exhibitor",
  speaker: "Speaker",
  superadmin: "Organiser",
  "vip/faculty": "VIP / Faculty",
};

export const PrintContent = React.forwardRef<HTMLDivElement, any>(
  (props, ref) => {
    const { user, setEditMode } = props;
    const { fullName, role, qrLabel, mealPref } = user;

    const renderField = (value, className = "") => (
      <ClickToEdit
        wrapperClass={`line ${className}`}
        initialValue={value}
        startEditing={() => {
          setEditMode(true);
        }}
        endEditing={() => {
          setEditMode(false);
        }}
      />
    );

    return (
      <div ref={ref}>
        <style type="text/css" media="print">
          {`
            @page { size: 4in 6in;
              margin: 3.1in 1cm 1cm 1cm;
            }
            .line {
              font-family: Georgia, 'Times New Roman', serif;
              font-size: 12pt;
              display: block !important;
              margin-bottom: 5pt;
              text-align: center;
              line-height: 1.3;
            }
            .fullName {
              font-size: 18pt;
              font-weight: bold;
              color: #000;
            }
            .role {
              font-style: italic;
              font-size: 16pt;
              margin-bottom: 5pt;
              color: #000;
            }
            .mealPref {
              font-size: 10pt;
              color: #000;
            }
            .CTE--wrapper:after {
              content: '' !important;
            }
            #qrLabel {
              display: block;
              margin: auto;
            }
          `}
        </style>
        {/* <div className="imageGap"></div> */}
        {renderField(fullName, "fullName")}
        {renderField(roleMapping[role], "role")}
        {renderField(mealPref, "mealPref")}
        {/* <img id="qrLabel" src={qrLabel} /> */}
      </div>
    );
  }
);

// const PrintContent = forwardRef(PrintContentRef)
export default PrintContent;
