import React, { useState, useEffect } from 'react'
import { debounce } from '@util'
import AsyncSelect from 'react-select/async';

// reference: https://www.learnwithjason.dev/blog/get-form-values-as-json/
const formToJSON = elements => [].reduce.call(elements, (data, element) => {
  data[element.name] = element.value;
  element.value = '';
  return data;
}, {});

const isValidExhibitor = (exhibitor) => {
  return !!exhibitor.email
}

const fetchExhibitors = (keyword) =>
  fetch(`/admin/exhibitors.json?q[email_cont]=${keyword}`)
    .then(resp => resp.json())
    .then(results =>
      results.map(
        (result) => ({ label: result.email, value: result.id })
      )
    )

const debouncedFetchExhibitors = debounce((keyword, callback) => {
  fetchExhibitors(keyword).then(results => callback(results))
}, 500)

export default function ExhibitorsInput({ exhibitors: oriExhibitors }) {
  const [exhibitors, setExhibitors] = useState(oriExhibitors || [])

  const onSubmit = (event) => {
    event.preventDefault()
    const exhibitor = formToJSON(event.target.elements);
    if (!isValidExhibitor(exhibitor)) return false
    setExhibitors([...exhibitors, exhibitor])
  }

  const removeExhibitor = (index) => {
    const selectedExhibitor = exhibitors[index]
    const confirmed = confirm(`Are you sure you want to remove Exhibitor with email: ${selectedExhibitor.email}?`)
    if(!confirmed) return

    const newExhibitors = [...exhibitors]
    newExhibitors.splice(index, 1)
    setExhibitors(newExhibitors)
  }

  const setExhibitorsFromOptions = (options) => {
    setExhibitors(
      options.map((option) => ({ id: option.value, email: option.label }))
    )
  }

  return (
    <>
      <label className='label'>Exhibitors</label>
      <form style={{ marginLeft: '25%', marginBottom: '10px' }} onSubmit={onSubmit}>
        <div style={{ marginBottom: '10px' }}>
          <label className='label' style={{ width: '10%' }}>Email:</label>
          <input type='text' name='email' />
          <AsyncSelect
            cacheOptions
            isMulti
            onChange={setExhibitorsFromOptions}
            loadOptions={debouncedFetchExhibitors}
            defaultOptions
          />
        </div>
        <button>Add exhibitor</button>
      </form>
      <ul style={{ marginLeft: '25%' }}>
        {
          exhibitors.map((exhibitor, index) => (
            <li
              style={{ fontSize: '14px', marginBottom: '10px' }} key={exhibitor.email}>
              <span style={{ display: 'inline-block', marginBottom: '5px'}}>
                <u style={{ marginBottom: '5px' }} >
                  Exhibitor {index + 1}
                </u>
                <span
                  style={{ color: 'red', marginLeft: '5px', cursor: 'pointer' }}
                  onClick={() => removeExhibitor(index)}
                >
                 x
                </span>
              </span>
              <ul>
                <li>Email: { exhibitor.email }</li>
              </ul>
              <input
                type='hidden'
                value={exhibitor.email}
                name='booth[booth_exhibitors][][exhibitor_id]'
              />
            </li>
          ))
        }
      </ul>
    </>
  )
}
