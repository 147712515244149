import React, { useState, useEffect, useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Booth } from "./Booth";
import axios from "axios";
import "./styles";

const token = document.getElementsByName("csrf-token")[0].content;
const defaultFetchHeader = {
  "X-CSRF-Token": token,
  "Content-Type": "application/x-www-form-urlencoded",
};

const convertBoothsJSON = (booths) => {
  return JSON.stringify(
    booths.map((b) => ({
      id: b.id,
      order: b.order,
    }))
  );
};

const fetchBooths = (callback) => {
  return () => {
    axios
      .request({
        url: "/admin/booths.json",
        method: "GET",
        headers: defaultFetchHeader,
      })
      .then((resp) => resp.data)
      .then((json) => callback(json))
      .catch((err) => console.log(err));
  };
};

const updateBoothPosition = (booths) => {
  const formData = new FormData();
  formData.append("booth_orders", convertBoothsJSON(booths));
  axios({
    method: "put",
    url: "/update-booth-order",
    headers: defaultFetchHeader,
    data: formData,
  })
    .then(() => {
      location.replace(location.href);
    })
    .catch(() => {
      location.replace(location.href);
    });
};

const Drag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-grip-vertical"
    viewBox="0 0 16 16"
  >
    <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
  </svg>
);

const BoothTitleWithDragIcon = ({ title, id }) => (
  <span style={{ display: "flex" }}>
    <Drag />
    <span>{title}</span>
  </span>
);

function BoothOrder({ booths: oriBooths }) {
  const [booths, setBooths] = useState([...oriBooths]);

  const moveBooth = useCallback(
    (dragIndex, hoverIndex) => {
      const newBooths = booths.map((booth, index) => {
        if (index == dragIndex) return { ...booth, order: hoverIndex };
        if (index >= hoverIndex && index <= dragIndex)
          return { ...booth, order: index + 1 };
        if (index <= hoverIndex && index >= dragIndex) {
          return { ...booth, order: index - 1 };
        } else return booth;
      });

      const dragBooth = newBooths[dragIndex];

      newBooths.splice(dragIndex, 1);
      newBooths.splice(hoverIndex, 0, dragBooth);

      setBooths(newBooths);
    },
    [booths]
  );

  const renderBooth = (booth, index) => {
    return (
      <div key={booth.id}>
        <Booth
          index={index}
          id={booth.id}
          title={<BoothTitleWithDragIcon title={booth.title} id={index} />}
          moveBooth={moveBooth}
        />
        <div style={{ visibility: "hidden" }}>
          <input type="hidden" value={booth.id} />
          <input type="hidden" value={index} />
        </div>
      </div>
    );
  };

  if (booths.length === 0) return "No booths found in this hall.";

  return (
    <>
      <div className="renderBoothContainer">
        {booths.map((booth, i) => renderBooth(booth, i))}
      </div>
      <div className="boothOrderButton">
        <button onClick={() => updateBoothPosition(booths)}>
          Update Booth Order
        </button>
      </div>
    </>
  );
}

export default function BoothOrderContainer() {
  const [booths, setBooths] = useState(null);
  useEffect(fetchBooths(setBooths), []);

  return (
    <DndProvider backend={HTML5Backend}>
      {booths && <BoothOrder booths={booths} />}
    </DndProvider>
  );
}
