import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAoOL3QkuSAOXCGIsvFggHwmTpMjjr5GXs",
  authDomain: "cloud365-72e6e.firebaseapp.com",
  databaseURL:
    "https://cloud365-72e6e-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "cloud365-72e6e",
  storageBucket: "cloud365-72e6e.appspot.com",
  messagingSenderId: "945788478161",
  appId: "1:945788478161:web:760f02b8090d6e21239bf0",
  measurementId: "G-9KP9KD2W4J",
};

firebase.initializeApp(firebaseConfig);
const firebaseDb = firebase.database();

export { firebaseDb };
