// src/controllers/hello_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['pdpa', 'submit', 'areaInterest', 'areaInterestOther']

  connect() {
    this.hasAreaInterestTarget ? this.toggleAreaInterestOther() : null
  }

  togglePDPA() {
    if (this.pdpaTarget.checked) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }

  toggleAreaInterestOther() {
    if (this.areaInterestTarget.value == 'Others') {
      this.areaInterestOtherTarget.classList.add('d-block')
      this.areaInterestOtherTarget.classList.remove('d-none')
    } else {
      this.areaInterestOtherTarget.classList.add('d-none')
      this.areaInterestOtherTarget.classList.remove('d-block')
    }
  }
}
