import { Controller } from "stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["form", "input", "button", "loading", "progress"];
  static values = { 
    checkStatusPath: String, // path to check if upload is complete (for uploads taking more than 30s)
    filesizeMbLimit: Number,
    redirectUrl: String // where to redirect after success
  }

  connect() {
    this.clearQueryParams()
  }

  upload(e) {
    e.preventDefault();
    let fileSizeMb = this.inputTarget.files[0].size / 1024 / 1024;
    if (fileSizeMb > this.filesizeMbLimitValue) {
      return alert(`File size exceeds ${this.filesizeMbLimitValue}Mb`);
    }
  
    let formData = new FormData(this.formTarget);

    this.buttonTarget.disabled = true;
    axios
      .request({
        method: "post",
        url: this.formTarget.action,
        data: formData,
        onUploadProgress: (p) => {
          this.loadingTarget.classList.remove("d-none");
          this.updateProgress(p.loaded, p.total);
        },
      })
      .then((response) => {
        // for uploads that are quicker than 30 seconds, this should suffice.
        window.location.href = this.redirectUrlValue;
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  updateProgress(loaded, total) {
    let percent = Math.round((loaded / total) * 100);
    console.log(`${percent}% (${loaded} / ${total} bytes)`);
    this.progressTarget.innerHTML = `Uploading ${percent}%`;

    if (loaded == total) {
      console.log('Upload complete!')
      this.progressTarget.innerHTML = `Processing video..`
      this.timer = setInterval(
        this.checkStatus,
        3000,
        this.checkStatusPathValue,
        this.redirectUrlValue
      )
    }
  }
    
  // for uploads that are MORE than 30 seconds, we will intermittently check with the server if the video has been uploaded
  checkStatus(path, redirect){
    axios
      .request({
        method: "get",
        url: path
      })
      .then((response) => {
        console.log(response.data)
        if(response.data.success){
          window.location.href = redirect;
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  clearQueryParams(){
    const queryString = window.location.search;
    if(queryString.length >= 0){
      let urlSearch = new URLSearchParams(queryString)
      if(urlSearch.get('video_upload_success')){
        window.history.replaceState({}, document.title, "/" + "users/dashboard");
      }
    }
  }
}
