import React, { useState } from "react";
import { debounce } from "@util";
import AsyncSelect from "react-select/async";

const token = document.getElementsByName("csrf-token")[0].content;

const defaultFetchHeader = {
  "X-CSRF-Token": token,
  "Content-Type": "application/json",
  // 'Content-Type': 'application/x-www-form-urlencoded'
};

const fetchBoothAgents = (keyword) =>
  fetch(`/admin/users.json?q[email_cont]=${keyword}&q[role_eq]=1`)
    .then((resp) => resp.json())
    .then((results) =>
      results.map((result) => ({ label: result.email, value: result.id }))
    );

const addBoothAgent = (boothId, userId) =>
  fetch(`/admin/booths/${boothId}/add_booth_agent`, {
    method: "POST",
    headers: defaultFetchHeader,
    body: JSON.stringify({
      user_id: userId,
      booth_id: boothId,
    }),
  }).then((resp) => (resp.ok ? resp.json() : Promise.reject(resp.status)));

const removeAgent = (boothId, userId) =>
  fetch(`/admin/booths/${boothId}/remove_booth_agent`, {
    method: "POST",
    headers: defaultFetchHeader,
    body: JSON.stringify({
      user_id: userId,
      booth_id: boothId,
    }),
  }).then((resp) => (resp.ok ? userId : Promise.reject(resp.status)));

const debouncedFetchBoothAgents = debounce((keyword, callback) => {
  fetchBoothAgents(keyword).then((results) => callback(results));
}, 500);

export default function BoothAgent(props) {
  const boothId = props.boothId;

  const [boothAgents, setBoothAgents] = useState(props.boothAgents);
  const [selected, setSelected] = useState(null);
  const [formStatus, setFormStatus] = useState("pending");

  const isFormLoading = formStatus == "loading";

  const onBoothAgentSelect = (option) => {
    setSelected(option);
  };
  const onAddBoothAgent = (e) => {
    e.preventDefault();
    const newBoothAgent = { userId: selected.value, email: selected.label };
    setFormStatus("loading");

    addBoothAgent(boothId, newBoothAgent.userId)
      .then(() => {
        setBoothAgents([...boothAgents, newBoothAgent]);
        setSelected(null);
        setFormStatus("pending");
        window.triggerToast("Booth Agent added!");
      })
      .catch((error) => {
        console.log(error);
        setSelected(null);
        setFormStatus("pending");
        // alert("Booth Agent has already been added.");
      });
  };

  const onRemoveBoothAgent = (e, index) => {
    e.preventDefault();
    const boothAgent = boothAgents[index];

    const confirm = window.confirm(
      `Are you sure you want to remove ${
        boothAgent.email ? boothAgent.email : boothAgent.user.email
      } from this booth?`
    );
    if (!confirm) return;

    removeAgent(boothId, boothAgent.id)
      .then(() => {
        const updatedList = [...boothAgents];
        updatedList.splice(index, 1);
        setBoothAgents(updatedList);
        window.triggerToast("Booth Agent removed.");
      })
      .catch((error) => {
        console.log(error);
        alert("Unable to remove booth agent.");
      });
  };

  return (
    <>
      <div className="mb-5">
        <div className="col-3 mb-2">
          <AsyncSelect
            cacheOptions
            value={selected}
            onChange={onBoothAgentSelect}
            loadOptions={debouncedFetchBoothAgents}
            defaultOptions
          />
        </div>
        <button
          disabled={!selected || isFormLoading}
          onClick={onAddBoothAgent}
          className="btn btn-primary"
        >
          {isFormLoading && "Loading"}
          {!isFormLoading && "Add Booth Agent"}
        </button>
      </div>
      <div className="mb-3">
        <h4>Booth Agent ({boothAgents.length})</h4>
        {boothAgents.map((boothAgent, index) => (
          <div className="row mb-2" key={boothAgent.id}>
            <div className="col-3">
              {boothAgent.email ? boothAgent.email : boothAgent.user.email}
            </div>
            <div className="col-3">
              <a
                onClick={(e) => onRemoveBoothAgent(e, index)}
                style={{ cursor: "pointer" }}
              >
                Remove
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
