import React, { useState } from 'react'

// reference: https://www.learnwithjason.dev/blog/get-form-values-as-json/
const formToJSON = elements => [].reduce.call(elements, (data, element) => {
  data[element.name] = element.value;
  element.value = '';
  return data;
}, {});

// - name
// - phone
// - email
export default function BoothAgents({ agents: oriAgents, limit }) {
  const [agents, setAgents] = useState(oriAgents)

  const limitReached = agents.length >= limit

  const onSubmit = (event) => {
    event.preventDefault()
    if (limitReached)
      return alert('Name card limit exceeded')
    const agent = formToJSON(event.target.elements);
    setAgents([...agents, agent])
  }

  const removeAgent = (index) => {
    const selectedAgent = agents[index]
    const confirmed = confirm(`Are you sure you want to remove name card for ${selectedAgent.name}?`)
    if(!confirmed) return

    const newAgents = [...agents]
    newAgents.splice(index, 1)
    setAgents(newAgents)
  }

  return (
    <>
      <label className='label'>PIC Business Name Cards ({agents.length} / {limit})</label>
      <form style={{ marginLeft: '25%', marginBottom: '10px' }} onSubmit={onSubmit}>
        <div style={{ marginBottom: '10px' }}>
          <label className='label' style={{ width: '10%' }}>Name:</label>
          <input type='text' style={{ display: 'inline' }} name='name' disabled={limitReached} />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label className='label' style={{ width: '10%' }}>Phone:</label>
          <input type='text' name='phone' disabled={limitReached} />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label className='label' style={{ width: '10%' }}>Email:</label>
          <input type='text' name='email' disabled={limitReached} />
        </div>
        <button disabled={limitReached} className='btn btn-primary'>
          { limitReached ? 'Limit reached' : 'Add name card' }
        </button>
      </form>
      <ul style={{ marginLeft: '25%' }}>
        {
          agents.map((agent, index) => (
            <li
              style={{ fontSize: '14px', marginBottom: '10px' }} key={agent.name + agent.email}>
              <span style={{ display: 'inline-block', marginBottom: '5px'}}>
                <u style={{ marginBottom: '5px' }} >
                  Name card #{index + 1}
                </u>
                <span
                  style={{ color: 'red', marginLeft: '5px', cursor: 'pointer' }}
                  onClick={() => removeAgent(index)}
                >
                 x
                </span>
              </span>
              <ul>
                <li>Name: { agent.name }</li>
                <li>Phone: { agent.phone }</li>
                <li>Email: { agent.email }</li>
              </ul>
              <input
                type='hidden'
                value={agent.name}
                name='booth[booth_agents][][name]'
              />
              <input
                type='hidden'
                value={agent.phone}
                name='booth[booth_agents][][phone]'
              />
              <input
                type='hidden'
                value={agent.email}
                name='booth[booth_agents][][email]'
              />
            </li>
          ))
        }
      </ul>
    </>
  )
}
