// src/controllers/hello_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkAllBox", "checkBox"];
  connect() {
    console.log(this.checkAllBoxTarget, this.checkBoxTargets)
  }

  checkAll(){
    if(this.checkAllBoxTarget.checked){
      this.checkBoxTargets.forEach(checkBox => {
        checkBox.checked = (!checkBox.disabled ? true : false)
      })
    } else {
      this.checkBoxTargets.forEach(checkBox => {
        checkBox.checked = false
      })
    }
  }
}
