// src/controllers/hello_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "output"];

  connect() {
    this.updateTally()
  }

  updateTally(){
    let total = 0
    this.inputTargets.map(input => {
      total += Number(input.value)
    })
    this.outputTarget.value = total
    this.outputTarget.readOnly = true
  }
}
