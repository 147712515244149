// src/controllers/hello_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  static values = { 
    mimeTypes: Array
  }

  validate(e){
    e.preventDefault()
    let file = this.inputTarget.files[0]
    if(this.mimeTypesValue.includes(file.type)){
      e.target.submit()
    } else {
      alert(`Invalid file type ${file.type}. Please upload ${this.mimeTypesValue.join(', ')}`)
    }
  }
}
