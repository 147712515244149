import React from 'react'

export default function NewTopupOrder({ formUrl, token, defaultEmail }) {
  console.log("topup")
  return (
    <div id="bootstrap-scope">
      <h3>New Top Up Order</h3>
      <div className="text-muted">
        <p className="m-0">
          Use this form to generate extra order for a particular user to pay off
          his/her balance.
        </p>
        <p className="m-0 mb-3">
          Created order will appear on the user's dashboard.
        </p>
      </div>
      <form action={formUrl} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />

        <div className="row mb-3 align-items-center">
          <div className="col-auto">
            <label className="col-form-label">User Email</label>
          </div>
          <div className="col-6">
            <input
              className="form-control"
              type="email"
              name="email"
              defaultValue={defaultEmail}
            />
          </div>
        </div>

        <div className="row g-3 mb-3 align-items-center">
          <div className="col-auto">
            <label className="col-form-label">Top Up Order Amount (RM)</label>
          </div>
          <div className="col-auto">
            <div className="input-group">
              <span className="input-group-text">RM</span>
              <input
                type="number"
                className="form-control"
                name="topup_amount"
                min={0}
              />
            </div>
          </div>
        </div>

        {/* This input gets saved into product's remark */}
        <div className="mb-3">
          <label className="col-form-label">Invoice Description</label>
          <input className="form-control mt-1" type="text" name="description" />
          <div className="form-text">
            This description will be displayed on invoice and receipt
          </div>
        </div>

        {/* This input gets saved into order's remark */}
        <div className="mb-3">
          <label className="col-form-label">Remark</label>
          <input className="form-control mt-1" type="text" name="remark" />
          <div className="form-text">
            This comment will be displayed to delegate on dashboard
          </div>
        </div>

        <input
          className="btn btn-primary mt-4"
          type="submit"
          value="Create Top Up Order"
        />
      </form>
    </div>
  )
}
