// src/controllers/hello_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "output", "btn", "total", "alert"];
  connect() {
    this.updateTally()
  }
  updateTally(){
    let numArray = this.outputTarget.innerHTML = this.inputTargets.map((input)=>{
      return Number(this.countWords(input.value))
    })

    let total = this.getSum(numArray)
    if(total > 500){
      this.btnTargets.map((btn)=> btn.disabled = true)
      this.totalTarget.classList.add('fw-bold', 'text-danger')
      this.alertTarget.classList.remove('d-none')
    } else {
      this.btnTargets.map((btn)=> btn.disabled = false)
      this.totalTarget.classList.remove('fw-bold', 'text-danger')
      this.alertTarget.classList.add('d-none')
    }

    this.outputTarget.innerHTML = total
  }

  countWords(str) {
    if (str.length <= 0){
      return 0 
    } else {
      return str.trim().split(/\s+/).length;
    }
  }
  
  getSum(array){
    return array.reduce(function(sum, value) {
      return sum + value;
    }, 0);
  }
}
