import React from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { Marker } from "./Marker";

export const DraggableMarker = React.memo(function DraggableMarker(props) {
  const { id, positionLeft, positionTop, assetUrl, markerType } = props;

  const [{ isDragging }, drag] = useDrag({
    accept: ItemTypes.BOX,
    item: {
      id,
      positionLeft,
      positionTop,
      type: ItemTypes.BOX,
      assetUrl,
      markerType,
    },
    type: ItemTypes.BOX,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      style={{
        position: "absolute",
        top: `${positionTop}px`,
        left: `${positionLeft}px`,
        opacity: isDragging ? 0.5 : 1,
        height: isDragging ? 0 : "30px",
      }}
      role="DraggableMarker"
    >
      <Marker markerImage={assetUrl} markerType={markerType} />
    </div>
  );
});
