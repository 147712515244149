import { element } from 'prop-types'
import React, { useState } from 'react'

// reference: https://www.learnwithjason.dev/blog/get-form-values-as-json/
const formToJSON = (elements) =>
  [].reduce.call(
    elements,
    (data, element) => {
      data[element.name] = element.value
      element.value = ''
      return data
    },
    {}
  )

export default function BoothAgents({
  agents: oriAgents,
  limit,
  countryCodes,
}) {
  const [agents, setAgents] = useState(oriAgents)

  const limitReached = agents.length >= limit

  const onSubmit = (event) => {
    event.preventDefault()
    if (limitReached) return alert('Maximum number of name cards reached!')
    const agent = formToJSON(event.target.elements)
    agent.phone = agent.code + agent.number
    setAgents([...agents, agent])
  }

  const removeAgent = (index) => {
    setAgents(agents.filter((agent, i) => i !== index))
  }

  return (
    <>
      <form id="picNameCardForm" className="mb-4" onSubmit={onSubmit}>
        <div>
          <div className="row mb-2">
            <div className="col-3 col-md-2">
              <label className="col-form-label" style={{ width: '10%' }}>
                Name:
              </label>
            </div>
            <div className="col-9 col-md-10">
              <input
                required
                className="form-control"
                type="text"
                style={{ display: 'inline' }}
                name="name"
                disabled={limitReached}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3 col-md-2">
              <label className="col-form-label" style={{ width: '10%' }}>
                Phone:
              </label>
            </div>
            <div className="col-9 col-md-10">
              <div className="row">
                <div className="col-4 country-code">
                  <input
                    // because formToJSON clears all the inputs when it's called,
                    // this will make sure the country code input is re-initialized
                    // with a default value is set after we added/removed agents
                    key={agents.length}
                    name="code"
                    className="form-control country-code__input"
                    defaultValue="+60"
                    placeholder="+60"
                    disabled={limitReached}
                  />
                  <select
                    className="form-control country-code__select"
                    placeholder="+60"
                    disabled={limitReached}
                  >
                    {countryCodes.map((code, index) => (
                      <option key={code[1] + index} value={code[1]}>
                        {code[0]}{' '}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-8">
                  <input
                    required
                    className="form-control"
                    type="text"
                    name="number"
                    disabled={limitReached}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3 col-md-2">
              <label className="col-form-label" style={{ width: '10%' }}>
                Email:
              </label>
            </div>
            <div className="col-9 col-md-10">
              <input
                required
                className="form-control"
                type="text"
                name="email"
                disabled={limitReached}
              />
            </div>
          </div>
        </div>
        <button
          disabled={limitReached}
          className="btn btn-outline-dark rounded mb-2"
        >
          {limitReached ? 'Limit Reached' : 'Add Card'}
        </button>
      </form>
      <h6 className="mb-1 fw-bold">
        Name Cards ({`${agents.length} / ${limit}`}):
      </h6>
      <ul id="picNameCardContainer">
        {agents.map((agent, index) => (
          <li className="nameCard" key={agent.name + agent.email + index}>
            <h6>{agent.name}</h6>
            <p className="my-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
              </svg>
              <span className="ms-1">{agent.phone}</span>
            </p>
            <p className="my-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z" />
              </svg>
              <span className="ms-1">{agent.email}</span>
            </p>
            <button
              className="deleteBtn fixedTopRight"
              onClick={() => removeAgent(index)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
              </svg>
            </button>
            <input
              type="hidden"
              value={agent.name}
              name="booth[booth_agents][][name]"
            />
            <input
              type="hidden"
              value={agent.phone}
              name="booth[booth_agents][][phone]"
            />
            <input
              type="hidden"
              value={agent.email}
              name="booth[booth_agents][][email]"
            />
          </li>
        ))}
        {
          agents.length == 0 && (
            <>
              <input
                type="hidden"
                value=""
                name="booth[booth_agents][]"
              />
            </>
          )
        }
      </ul>
    </>
  )
}
