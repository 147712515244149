import axios from "axios";
import React, { useCallback, useState } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableMarker } from "./DraggableMarker";
import "./styles.scss";

const token = document.getElementsByName("csrf-token")[0].content;
const defaultFetchHeader = {
  "X-CSRF-Token": token,
  "Content-Type": "application/x-www-form-urlencoded",
};

const convertMarkersJSON = (markers) =>
  JSON.stringify(
    markers.map((m) => ({
      id: m.id,
      position_left: m.positionLeft,
      position_top: m.positionTop,
    }))
  );

export const LobbyContainer = ({ props }) => {
  const [lobbyMarkers, setLobbyMarkers] = useState(
    props.lobbyPage.lobbyMarkers.map(
      ({ id, positionLeft, positionTop, assetUrl, markerType }) => ({
        id,
        positionLeft,
        positionTop,
        assetUrl,
        markerType,
      })
    )
  );

  const moveBox = useCallback(
    (id, positionLeft, positionTop) => {
      setLobbyMarkers(
        lobbyMarkers.map((marker) =>
          marker.id == id
            ? {
                ...marker,
                positionLeft,
                positionTop,
              }
            : marker
        )
      );
    },
    [lobbyMarkers]
  );

  const [, drop] = useDrop(
    {
      accept: "box",
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        let positionLeft = Math.round(item.positionLeft + delta.x);
        let positionTop = Math.round(item.positionTop + delta.y);
        moveBox(item.id, positionLeft, positionTop);
        return undefined;
      },
    },
    [moveBox]
  );

  const updateLobbyMarkerPosition = () => {
    const formData = new FormData();
    formData.append("lobby_markers", convertMarkersJSON(lobbyMarkers));
    axios({
      method: "patch",
      url: props.url,
      headers: defaultFetchHeader,
      data: formData,
    })
      .then(() => {
        location.replace(location.href);
      })
      .catch(() => {
        location.replace(location.href);
      });
  };

  return (
    <>
      <div
        id="lobbyMarkerContainer"
        style={{
          backgroundImage: `url(${props.lobbyPage.desktopBackgroundImage})`,
        }}
        ref={drop}
      >
        {Object.keys(lobbyMarkers).map((key) => {
          return <DraggableMarker key={key} id={key} {...lobbyMarkers[key]} />;
        })}
      </div>
      <div>
        <button id="buttonUpdate" onClick={() => updateLobbyMarkerPosition()}>
          {`Update Position`}
        </button>
      </div>
    </>
  );
};

const LobbyMarkerContainer = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <LobbyContainer props={props} />
    </DndProvider>
  );
};

export default LobbyMarkerContainer;
