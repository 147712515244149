import * as bootstrap from 'bootstrap'
import Swiper, { Navigation, Pagination } from 'swiper'



export const initAwardsSwiper = () => {
  if (!document.querySelector('#awardsSwiper')) return

  console.log('initAwardsSwiper')
  const swiper = new Swiper('#awardsSwiper', {
    modules: [Navigation, Pagination],
    cssMode: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
    },
  })
}