import React from "react";
import "../styles";

export const DownloadButton = ({ onClick, buttonTitle, helperMessage }) => {
  return (
    <div>
      <button className="download-button" onClick={onClick}>
        {buttonTitle}
      </button>
      <small className="text-muted">{helperMessage}</small>
      <br />
    </div>
  );
};

export const DeleteButton = ({ onClick, buttonTitle, helperMessage }) => {
  return (
    <div>
      <button className="delete-button" onClick={onClick}>
        {buttonTitle}
      </button>
      <br />
      <small className="text-muted">{helperMessage}</small>
      <br />
    </div>
  );
};
