import React, { useState } from 'react'

// reference: https://www.learnwithjason.dev/blog/get-form-values-as-json/
const formToJSON = elements => [].reduce.call(elements, (data, element) => {
  data[element.name] = element.value;
  element.value = '';
  return data;
}, {});

// - name
// - phone
// - email
export default function QuestionChoicesInput({ choices: oriChoices, correctChoice: oriCorrectChoice }) {
  const [choices, setChoices] = useState(oriChoices.map(c => ({ title: c })))
  const [correctChoice, setCorrectChoice] = useState(oriCorrectChoice)

  const onSubmit = (event) => {
    event.preventDefault()
    const choice = formToJSON(event.target.elements);
    setChoices([...choices, choice])
  }

  const markCorrectChoice = (index) => {
    setCorrectChoice(index)
  }

  const removeChoice = (index) => {
    const selectedChoice = choices[index]
    const confirmed = confirm(`Are you sure you want to remove Choice ${index + 1}?`)
    if(!confirmed) return

    const newChoices = [...choices]
    newChoices.splice(index, 1)
    setChoices(newChoices)
  }

  return (
    <>
      <label className='label'>Choices</label>
      <form style={{ marginLeft: '25%', marginBottom: '10px' }} onSubmit={onSubmit}>
        <div style={{ marginBottom: '10px' }}>
          <input
            type='text'
            style={{ display: 'inline' }}
            name='title'
            placeholder='Add new choice here'
          />
        </div>
        <button>Add choice</button>
      </form>
      <ul style={{ marginLeft: '25%' }}>
        {
          choices.map((choice, index) => (
            <li
              style={{ fontSize: '14px', marginBottom: '10px' }} key={choice.title}>
              <span style={{ display: 'inline-block', marginBottom: '5px'}}>
                <span style={{ marginBottom: '5px' }} >
                  {index + 1}{'. '}
                </span>
                <span
                  style={{
                    textDecoration: index == correctChoice ? 'underline green' : '',
                    color: index == correctChoice ? 'green' : '',
                    cursor: 'pointer'
                  }}
                  onClick={() => markCorrectChoice(index)}
                >
                  { index == correctChoice && <>&#10003;</>} { choice.title }
                </span>
                <br />
                <span
                  style={{ color: 'red', marginLeft: '5px', cursor: 'pointer' }}
                  onClick={() => removeChoice(index)}
                >
                  Remove
                </span>
              </span>
              <input
                type='hidden'
                value={choice.title}
                name='question[choices][]'
              />
            </li>
          ))
        }
        <input
          type='hidden'
          value={correctChoice}
          name='question[answer_index]'
        />
      </ul>
    </>
  )
}