import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { GalleryFile } from "./GalleryFile";
import "./styles";

const token = document.getElementsByName("csrf-token")[0].content;
const defaultFetchHeader = {
  "X-CSRF-Token": token,
  "Content-Type": "application/x-www-form-urlencoded",
};

const convertGalleryFiles = (files) => {
  return JSON.stringify(
    files.map((b) => ({
      id: b.id,
      order: b.order,
    }))
  );
};

const fetchGalleryFiles = (callback) => {
  return () => {
    axios
      .request({
        url: "/admin/gallery_files.json",
        method: "GET",
        headers: defaultFetchHeader,
      })
      .then((resp) => resp.data)
      .then((json) => callback(json))
      .catch((err) => console.log(err));
  };
};

const updateGalleryFilePosition = (galleryFile) => {
  console.log(galleryFile);
  const formData = new FormData();
  formData.append("files_orders", convertGalleryFiles(galleryFile));
  axios({
    method: "put",
    url: "/update-files-order",
    headers: defaultFetchHeader,
    data: formData,
  })
    .then(() => {
      location.replace(location.href);
    })
    .catch(() => {
      location.replace(location.href);
    });
};

const Drag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-grip-vertical"
    viewBox="0 0 16 16"
  >
    <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
  </svg>
);

const FileTitleWithDragIcon = ({ title }) => (
  <span style={{ display: "flex" }}>
    <Drag />
    <span>{title}</span>
  </span>
);

function GalleryFileOrder({ files: oriFiles }) {
  const [files, setFiles] = useState([...oriFiles]);

  const moveFile = useCallback(
    (dragIndex, hoverIndex) => {
      const newFiles = files.map((file, index) => {
        if (index == dragIndex) return { ...file, order: hoverIndex };
        if (index >= hoverIndex && index <= dragIndex)
          return { ...file, order: index + 1 };
        if (index <= hoverIndex && index >= dragIndex) {
          return { ...file, order: index - 1 };
        } else return file;
      });

      const dragFile = newFiles[dragIndex];

      newFiles.splice(dragIndex, 1);
      newFiles.splice(hoverIndex, 0, dragFile);

      setFiles(newFiles);
    },
    [files]
  );

  const renderFile = (file, index) => {
    return (
      <div key={file.id}>
        <GalleryFile
          index={index}
          id={file.id}
          title={<FileTitleWithDragIcon title={file.title} id={index} />}
          moveFile={moveFile}
        />
        <div style={{ visibility: "hidden" }}>
          <input type="hidden" value={file.id} />
          <input type="hidden" value={index} />
        </div>
      </div>
    );
  };

  if (files.length === 0) return "No files found in this folder.";

  return (
    <>
      <div className="renderFileContainer">
        {files.map((file, i) => renderFile(file, i))}
      </div>
      <div className="fileOrderButton">
        <button onClick={() => updateGalleryFilePosition(files)}>
          Update File Order
        </button>
      </div>
    </>
  );
}

export default function GalleryFileOrderContainer() {
  const [files, setFiles] = useState(null);
  useEffect(fetchGalleryFiles(setFiles), []);
  console.log(files);
  return (
    <DndProvider backend={HTML5Backend}>
      {files && <GalleryFileOrder files={files} />}
    </DndProvider>
  );
}
