// src/controllers/hello_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["textField", "output"];
  connect() {
    this.updateCount()
  }

  updateCount(){
    this.outputTarget.innerHTML = this.countWords(this.textFieldTarget.value)
  }

  countWords(str) {
    if (str.length <= 0){
      return 0 
    } else {
      return str.trim().split(/\s+/).length;
    }
  }
}
